import React from "react";
import { ReactComponent as ComingSoonIcon } from "assets/wip-icon.svg";

export default function InboundWIPPage() {
  return (
    <div className="flex flex-col items-center h-full">
      <ComingSoonIcon className="w-60 h-60 mt-4" />
      <h1 className="text-4xl font-bold text-superbill-jacarta">
        Coming Soon!
      </h1>
      <p className="text-base mt-4 text-superbill-jacarta">
        We are working hard to bring you this feature. If you are interested in
        this feature, please{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://calendly.com/sam-lj4/superdial-payer-phone-call-automation"
        >
          <span className="hover-underline-animation">contact our support team.</span>
          
        </a>
      </p>
    </div>
  );
}
