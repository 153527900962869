import Select, { components, OptionProps } from "react-select";
import { Controller } from 'react-hook-form';
export const SingleValue = (props) => {
  return (
    <components.SingleValue {...props} className="flex items-center">
      <div className="mx-1 ">{props.data.icon}</div>
      <div className="mx-1 font-sans">{props.data.label}</div>
    </components.SingleValue>
  );
};
export const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center group">
        <div className="mx-1 fill-jacarta group-hover:fill-superbill-indigo">{props.data.icon}</div>
        <div className="mx-1">{props.data.label}</div>
      </div>
    </components.Option>
  );
};

export const RobodialerSelect = (name, control, robodialersData) => {
  return (
    <>
      <label htmlFor={name} className='text-sm text-su  perbill-jacarta'>Robodialers</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            placeholder={'Select Robodialer'}
            components={{
              Option,
              SingleValue: SingleValue,
              IndicatorSeparator: () => null,
              IndicatorsContainer: () => null,

            }}
            menuPortalTarget={document.body}
            styles={{
              singleValue: (provided) => ({
                ...provided,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (baseStyles, state) => ({
                ...baseStyles,
                '&:hover': { backgroundColor: '#EFF1F6', cursor: 'pointer' },
                boxShadow: state.isFocused ? '0px 0px 0px 3px #E4E8FD' : 'none',
                borderColor: state.isFocused ? '#4a43cd' : '#ffffff',
                //zIndex: 11
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '15px',
                fontFamily: 'DM Sans',
                backgroundColor: state.isFocused ? '#f5f6fb' : '#ffffff',
                color: state.isFocused ? '#4a43cd' : '#38395B',
                ':active': {
                  ...baseStyles[':active'],
                  backgroundColor: '#D0D3F2',
                },
              })
            }}
            isSearchable={true}
            className='mt-1 block w-full border border-superpay-soap rounded-md'
            {...field}
            options={robodialersData?.map((e) => {
              return { value: e.id, label: e.name }
            })}
          />
        )}
        rules={{ required: false }}
      />
    </>
  )
}