import React, { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { getRobodialersResponseType } from 'types/types';
import { copyScript, getRobodialers } from "apis/agent";
import { RobodialerSelect } from "./components/robodialer_select";


// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  setSelectedScript: React.Dispatch<React.SetStateAction<number | null>>;
  data:{
    scriptId: string;
    robodialerId: string;
  }
  onSuccess: () => void
}


export default function CopyScriptsModal({ modalIsOpen, setSelectedScript, data, onSuccess }: Props) {
  const [robodialersData, setRobodialersData] = useState<null | getRobodialersResponseType>(null);
  const { register, handleSubmit, control, reset, watch, setValue, formState: { errors } } = useForm();
  
  const [isLoading, setIsLoading] = useState<boolean>(false)
  
  const onSubmit = async () => {
    let destinationRobodialerId = watch('destination_robodialer_id')?.value ?? null
    setIsLoading(true)
    const response = await copyScript(data.robodialerId, data.scriptId, {destination_robodialer_id:destinationRobodialerId})
    setIsLoading(false)
    if(response.httpCode != 200){
      toast.error(response.httpCode + ' Unable to Copy: ' + response?.data?.error ?? " An error occurred", {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
        },
      });
    }else{
      toast.success('Script Copied Successfully:' + response.data.data.new_script_id , {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
        },
      });
      if (onSuccess){
        onSuccess()
      }
    }
    toggleModal();
  };
  

  const toggleModal = () => {
    reset()
    setSelectedScript(null);
  };
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      const { data } = await getRobodialers();
      setIsLoading(false)
      setRobodialersData(data)
    }
    if(!robodialersData){
      fetch()
    }
  
  }, []);

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="flex flex-col h-full divide-y font-sans">
          {/* Navbar */}
          <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
            Copy Script {data.scriptId}
          </nav>
          <div className="flex-grow overflow-y-auto p-9 !z-50">
            {RobodialerSelect('destination_robodialer_id', control  , robodialersData?.robodialers)}
          </div>
          {/* Toolbar */}
          <div className="flex flex-row justify-between px-4 py-3">
            <button
              className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
              onClick={() => { setSelectedScript(null) }}
            >
              Close
            </button>
            <button
              disabled={isLoading}
              className='bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300'
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? <Spinner /> : <>Submit</>}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
