import React, { useState } from "react";
import Modal from "react-modal";
import ConfiguringAgentPage from "./pages/configuring-agent-page";
import InboundScriptInstructionsPage from "./pages/instructions-page";
import NewInboundScriptPage from "./pages/new-script-page";

// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedInboundScriptId: React.Dispatch<React.SetStateAction<string | null>>;
  
  selectedInboundScriptId: string | null;
  scriptData: any;
}

export default function NewInboundScriptModal({ modalIsOpen, setModalIsOpen, setSelectedInboundScriptId, selectedInboundScriptId, scriptData }: Props) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState<'instructions' | 'form' | 'configuring'>('instructions');
  console.log('selectedInboundScriptId', selectedInboundScriptId);
  console.log('scriptData', scriptData);
  
  
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    setCurrentStatus('instructions');
    setSelectedInboundScriptId(null)
  }
  const closeModal = () => {
    setModalIsOpen(false)
    setCurrentStatus('instructions');
    setSelectedInboundScriptId(null)
  }

  return (
    <div>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Modal"
      >
        {currentStatus === 'instructions' ?
          <InboundScriptInstructionsPage onClose={() => {closeModal()}} goToCreatePage={() => {
            setCurrentStatus('form');
          }}/>
        : null}
        {currentStatus === 'form' ?
          <NewInboundScriptPage  selectedScriptId={selectedInboundScriptId} scriptData={scriptData}
          onClose={() => {closeModal()}} onSubmit={() => {
            setCurrentStatus('configuring');
          }}/>
        : null}
        {currentStatus === 'configuring' ?
          <ConfiguringAgentPage onConfigurationComplete={() => {closeModal()}} />
        : null}
        
      </Modal>
    </div>
  );
};
