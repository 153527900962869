import React from "react";
import { DateTime } from 'luxon';

const CallDetails = ({callData, setSelectedCall}) => {
  return (
    <div className="p-6 min-h-screen">
      {/* Back to Results */}
      <div className="text-superbill-slate-grey mb-4 cursor-pointer" onClick={() => setSelectedCall(null)}>
        ← Back to All Results
      </div>

      {callData ?
      <>
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4 mb-4">
          <h1 className="text-xl">{callData.callId}</h1>
          <span className="text-gray-500">
            {DateTime.fromJSDate(new Date(callData.added_at)).toFormat("MM/dd/yyyy")}
          </span>
        </div>

        {/* Content */}
        <div className="grid grid-cols-10 gap-6">
          {/* Transcript */}
          <div className="bg-white shadow rounded p-4 border border-superpay-soap h-[700px] overflow-y-auto col-span-6">
            <p className="text-sm leading-relaxed">
              {callData?.transcript.split("\n").map((line, index) => {
                return (
                  <span key={index}>
                    {line}
                    {line?.startsWith('AI:') ? <br /> : null}
                    <br />
                  </span>
                );
              })}
            </p>
          </div>

          {/* Cards */}
          <div className="flex flex-col gap-4 col-span-4">
            {/* Call started at */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Call started at
              </h2>
              <p className="text-xl">{callData?.started_at}</p>
            </div>
            {/* Call ended at */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Call ended at
              </h2>
              <p className="text-xl">{callData?.ended_at}</p>
            </div>
            {/* Call Duration */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Call Duration
              </h2>
              <p className="text-xl">{callData?.duration}</p>
            </div>

            {/* Phone Number */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Phone Number
              </h2>
              <p className="text-lg font-medium">{callData.phoneNumber?.number}</p>
            </div>
            {/* Phone Number */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Customer Phone Number
              </h2>
              <p className="text-lg font-medium">{callData.customer?.number}</p>
            </div>
            {/* Tags */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">Script</h2>
              <p className="text-lg font-medium">
                {callData.scriptName ?? callData.scriptId}
              </p>
            </div>

            {/* Call Summary */}
            <div className="shadow rounded p-4 border border-superpay-soap">
              <h2 className="text-sm font-semibold text-superbill-jacarta">
                Call Summary
              </h2>
              <p className="text-sm leading-relaxed">
                {callData.summary ?? "No summary available."}
              </p>
            </div>
          </div>
        </div>
      </>  
      :
       <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
          No inbound call details available
        </div>
      }

    </div>
  );
};

export default CallDetails;