import { ReactComponent as SuperDialIcon } from 'assets/superdial-logo-icon-blue.svg';
import Loader from 'components/Loader';
import { Fragment, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import {  Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getAuth } from 'firebase/auth';
import { WebhookDataType, RobodialerUser } from '../types/types';
import { getRobodialerUser, updateRobodialerUser, getWebhookData } from '../apis/robodialer';
import SuperDialTutorialModal from './tutorial/superdial-tutorial-modal';
import InviteMembersModal from './dashboard/invite-members-modal';
import { ReactComponent as ResultsUnselectedIcon } from 'assets/results-unselected.svg'
import { ReactComponent as ResultsSelectedIcon } from 'assets/results-selected.svg'
import { ReactComponent as InboundResultsSelectedIcon } from 'assets/inbound-selected.svg'
import { ReactComponent as InboundResultsUnSelectedIcon } from 'assets/inbound-unselected.svg'
import { ReactComponent as ScriptsUnselectedIcon } from 'assets/scripts-unselected.svg';
import { ReactComponent as ScriptsSelectedIcon } from 'assets/scripts-selected.svg';
import { ReactComponent as PatientsUnselectedIcon } from 'assets/patients-unselected.svg';
import { ReactComponent as PatientsSelectedIcon } from 'assets/patients-selected.svg';
import { ReactComponent as APIUnselectedIcon } from 'assets/api-unselected.svg';
import { ReactComponent as APISelectedIcon } from 'assets/api-selected.svg';
import { ReactComponent as WebhooksUnselectedIcon } from 'assets/webshooks-unselected.svg';
import { ReactComponent as WebhooksSelectedIcon } from 'assets/webhooks-selected.svg';
import { ReactComponent as CallSettingsUnselectedIcon } from 'assets/callSettings-unselected.svg';
import { ReactComponent as CallSettingsSelectedIcon } from 'assets/callSettings-selected.svg';
import { ReactComponent as TeamUnselectedIcon } from 'assets/team-unselected.svg';
import { ReactComponent as TeamSelectedIcon } from 'assets/team-selected.svg';
import { ReactComponent as ResourcesUnselectedIcon } from 'assets/resources-unselected.svg';
import { ReactComponent as ResourcesSelectedIcon } from 'assets/resources-selected.svg';
import { ReactComponent as PMSUnselectedIcon } from 'assets/pms-unselected.svg';
import { ReactComponent as PMSSelectedIcon } from 'assets/pms-selected.svg';
import { ReactComponent as NavbarLeft } from 'assets/navbar-arrow-left.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link-slate.svg';
import { ReactComponent as SignOut} from 'assets/sign-out.svg';


type PathType = {
  name: string;
  href: string;
  current: boolean;
  UnselectedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  SelectedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}
export default function SuperDialDashboard({ userData }) {
  const [inviteUserOpenState, setInviteUserOpenState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [robodialerData, setRobodialerData] = useState<RobodialerUser | null>(null);
  const [tutorialModalOpenState, setTutorialModalOpenState] = useState<boolean>(false);
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);
  const [webhookData, setWebhookData] = useState<WebhookDataType | null>(null);


  const location = useLocation();
  const navigate = useNavigate();

  const initialNavigation: PathType[] = [
    { name: 'Outbound Call Results', href: '/super-dial-dashboard/script-results', current: false, UnselectedIcon: ResultsUnselectedIcon, SelectedIcon: ResultsSelectedIcon },
    { name: 'Inbound Call Results', href: '/super-dial-dashboard/inbound-results', current: false, UnselectedIcon: InboundResultsUnSelectedIcon, SelectedIcon: InboundResultsSelectedIcon },
    { name: 'Insurance Verifications', href: '/super-dial-dashboard/pms-insurance-verifications', current: false, UnselectedIcon: PMSUnselectedIcon, SelectedIcon: PMSSelectedIcon },
    { name: 'Scripts', href: '/super-dial-dashboard/home', current: false, UnselectedIcon: ScriptsUnselectedIcon, SelectedIcon: ScriptsSelectedIcon },
    { name: 'API', href: '/super-dial-dashboard/keys', current: false, UnselectedIcon: APIUnselectedIcon, SelectedIcon: APISelectedIcon  },
    { name: 'Webhooks', href: '/super-dial-dashboard/webhooks', current: false, UnselectedIcon: WebhooksUnselectedIcon, SelectedIcon: WebhooksSelectedIcon },
    { name: 'Call Settings', href: '/super-dial-dashboard/call-settings', current: false, UnselectedIcon: CallSettingsUnselectedIcon, SelectedIcon: CallSettingsSelectedIcon },
    { name: 'Team', href: '/super-dial-dashboard/team', current: false, UnselectedIcon: TeamUnselectedIcon, SelectedIcon: TeamSelectedIcon },
    { name: 'Resources', href: '/super-dial-dashboard/resources', current: false, UnselectedIcon: ResourcesUnselectedIcon, SelectedIcon: ResourcesSelectedIcon },
  ];

  const [navigation, setNavigation] = useState<PathType[]>(initialNavigation);
  const setPath = () => {
    setNavigation((prevNavigation) => {
      const updatedNavigation = prevNavigation.map((item) => ({
        ...item,
        //current: item.href === location.pathname,
        current: item.href === location.pathname.split('/').slice(0, 3).join('/'),
      }));
      const hasCurrent = updatedNavigation.some((item) => item.current);
      if (!hasCurrent) {
        updatedNavigation[0].current = true; // set current:true
      }
      return updatedNavigation;
    });
  }

  const refreshSecret = async () => {
    setLoading(true)
    let { data } = await getWebhookData();
    setWebhookData(data as WebhookDataType)
  }

  useEffect(() => {
    setPath()
    const fetch = async () => {
      const userResponse = await getRobodialerUser();
      const webhookResponse = await getWebhookData();
      if (webhookResponse.data) {
        setWebhookData(webhookResponse.data as WebhookDataType);
      } 
      setTutorialModalOpenState(!userResponse.data.viewedTutorial)
      setRobodialerData(userResponse.data)

      if (!userResponse?.data?.pmsActive) {
        setNavigation((prevNavigation) =>
          prevNavigation.filter(
            (item) => item.name !== 'Insurance Verifications'
          )
        );
      }

      if (location.pathname === '/super-dial-dashboard') {
        if (userResponse?.data?.pmsActive) {
          navigate('/super-dial-dashboard/pms-insurance-verifications')
        } else if (userResponse?.data?.hasScriptResults) {
          navigate('/super-dial-dashboard/script-results')
        } else {
          navigate('super-dial-dashboard/home')
        }
      }
      
      setLoading(false)
    }
    fetch()
  }, []);
  useEffect(() => {
    setPath()
  }, [location.pathname]);
  useEffect(() => {
    if (webhookData) {
      setLoading(false)
    }
  }, [webhookData])

  useEffect(() => {
    if (robodialerData && robodialerData?.pmsActive) {
      setNavigation((prevNavigation) => [
        ...prevNavigation,
        { name: 'Patients', href: '/super-dial-dashboard/patients', current: false, UnselectedIcon: PatientsUnselectedIcon, SelectedIcon: PatientsSelectedIcon},
      ]);
      setPath()
    }
  }, [robodialerData]);

  return (

    <>
      <Toaster  toastOptions={{duration: 12000}}/>
      {loading ? (
        <>
          <div className='w-full h-full flex justify-center items-center'>
            {' '}
            <Loader />{' '}
          </div>
        </>
      ) : null}
      {!loading ? (
        <>
        <div className='flex'>
          <div className={`relative border border-r bg-white border-superbill-soap ${sideNavOpen? 'min-w-[240px]': 'min-w-[60px]' } `}>
              <div className="p-2">
                <div className={`text-superbill-jacarta text-base font-jakarta flex items-center ${sideNavOpen ? 'justify-between':'justify-center'}`}>
                  {sideNavOpen ? 
                  <div className='flex items-center'>
                    <SuperDialIcon className="w-[30px] mr-2" />
                    SUPER<strong>DIAL</strong>
                  </div>
                  : null}
                  <button onClick={() => {setSideNavOpen(!sideNavOpen)}} className='bg-white p-1 rounded border border-superbill-soap hover:bg-superbill-background-base'>
                    <NavbarLeft className={sideNavOpen ? '':'rotate-180'}/>
                  </button>
                </div>
                <nav className='justify-end mt-16'>
                  <ul className="items-center">
                    {navigation.map((item) => (
                      <li key={item.name} className=''>
                        <Link to={item.href}>
                          <div
                            onClick={() => {
                              //navigate(item.href)
                            }}
                            className={classNames(
                              item.current
                                ? 'text-superbill-indigo font-bold bg-superbill-background-base rounded p-2'
                                : ' text-superbill-slate-grey hover:text-indigo-600 font-normal bg-white rounded p-2',
                              'text-base cursor-pointer font-jakarta mt-1 flex items-center',
                              sideNavOpen ? '': 'justify-center'
                            )}
                          >
                            {item.current ? 
                            <item.SelectedIcon className='w-[16px] h-[16px]' />
                            :
                            <item.UnselectedIcon className='w-[16px] h-[16px]' />
                            } 
                            {/* <item.UnselectedIcon className='w-[16px] h-[16px]' /> */}
                            {sideNavOpen ? <div className='ml-2'>{item.name}</div> : null}
                          </div>
                        </Link>

                      </li>
                    ))}
                  </ul>
                </nav>

              </div>
              {sideNavOpen?
              <div className='fixed bottom-10 p-2'>
              <a href="https://superdial.readme.io/" target="_blank" rel="noreferrer" className='hover:fill-superbill-indigo'>
                <div className='text-superbill-slate-grey hover:text-indigo-600 font-normal bg-white rounded p-2 flex items-center'>
                  Documentation
                  <ExternalLinkIcon className='ml-2 fill-superbill-slate-grey'/>
                </div>
                </a>
              </div>
              :null}
              <Menu
              >
                <MenuButton >
                  <div className={`fixed bottom-0 py-2 pl-2 border-t border-superbill-soap hover:bg-superbill-background-base bg-white flex items-center ${sideNavOpen ? 'w-[238px]' : 'w-[58px] justify-center'}`}>
                <div className='items-center rounded-full p-1  relative inline-block text-left z-50 bg-gradient-to-r from-superbill-indigo to-superbill-indigo-dark'>
                <div className='flex items-center justify-center w-full'>
                  
                    <span className="text-white text-xs flex items-center justify-center h-4 w-4 font-semibold">
                      {" "}
                      {/* <UserIcon className="h-[14px] fill-white"/> */}
                      {robodialerData?.firstName && robodialerData?.firstName[0]?.toUpperCase()}
                      {" "}
                    </span>
                  
                </div>
                </div>
                {sideNavOpen ? 
                <div className='pl-2 text-superbill-jacarta'>
              {robodialerData?.firstName}
              </div>
              : null}
              </div>
                </MenuButton>


                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <MenuItems className='z-50 absolute bottom-12 left-2 mt-2 w-fit whitespace-nowrap origin-top-right border border-superbill-soap rounded bg-white shadow-lg focus:outline-none overflow-hidden min-w-[200px]'>
                    <MenuItem >
                      <div
                        className={'text-superbill-jacarta w-full px-4 py-3 text-left font-sans'}
                      >
                        <div className="text-base font-bold font-jakarta" >{robodialerData?.firstName}</div>
                        <div className="text-sm text-superbill-slate-grey capitalize" >{robodialerData?.robodialerNameAtSignUp} • {robodialerData?.role?.toLocaleLowerCase() === 'admin' ? 'Admin' : 'Basic User'}</div>
                      </div>
                    </MenuItem>
                    <div className='border-b border-1 border-superbill-soap'></div>
                    {/* {robodialerData?.role?.toLocaleLowerCase() === 'admin' ? */}
                      <MenuItem >
                        <div
                          onClick={() => setInviteUserOpenState(true)}
                          className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superbill-slate-grey rounded-t'}`}
                        >
                          <div className="text-base" >Invite User</div>
                        </div>
                      </MenuItem>
                      {/* : null} */}
                    <MenuItem >
                      <a rel="noreferrer" target='_blank' href='https://calendly.com/sam-lj4/superdial-payer-phone-call-automation'>
                        <div
                          className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                        >
                          <div className="text-base" >Contact Support</div>
                        </div>
                      </a>
                    </MenuItem>
                    <div className='border-b border-1 border-superbill-soap'></div>
                    <a rel="noreferrer" target='_blank' href='https://www.thesuperbill.com/terms'>
                        <div
                          className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                        >
                          <div className="text-base" >Terms of Service</div>
                        </div>
                      </a>
                    <MenuItem >
                      <a rel="noreferrer" target='_blank' href='https://www.thesuperbill.com/privacy'>
                        <div
                          className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                        >
                          <div className="text-base" >Privacy Notice</div>
                        </div>
                      </a>
                    </MenuItem>
                    <MenuItem >
                      <a rel="noreferrer" target='_blank' href='https://www.thesuperbill.com/release-notes'>
                        <div
                          className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                        >
                          <div className="text-base" >Change Log</div>
                        </div>
                      </a>
                    </MenuItem>

                    <div className='border-b border-1 border-superbill-soap'></div>
                    <MenuItem >
                      <div
                        onClick={() => {
                          getAuth().signOut();
                          window.location.assign('/super-dial-sign-in')
                        }}
                        className={`text-superbill-jacarta cursor-pointer w-full px-4 py-2 text-left flex items-center ease-in-out duration-150 font-sans ${true ? 'hover:bg-superbill-ghost-white hover:text-superbill-indigo hover:fill-superbill-indigo' : ' text-superpay-light-text-gray rounded-t'}`}
                      >
                        <SignOut className ='mr-2'/>
                        <div className="text-base" >Sign Out</div>
                      </div>
                    </MenuItem>
                  </MenuItems>
                </Transition>

              </Menu>

            </div>
          <div className='flex flex-col h-screen w-full overflow-y-auto p-4'>
            <main className="mx-auto flex-1 w-full">
            <Outlet context={[{ 
              userData: robodialerData, 
              setUserData: setRobodialerData, 
              webhookData, 
              refreshSecret, 
              setInviteUserOpenState 
            }]} />
            </main>
            {/* <div className='mx-5 flex items-center p-2 ml-3 font-jakarta mt-10'>
              <div className='mr-2 text-sm text-superpay-light-text-gray font-bold'>SuperDial</div>
              <div className='mx-2 text-sm'><a rel='noopener noreferrer' target='_blank' href='https://www.thesuperbill.com/terms' className="hover-underline-animation cursor-pointer">Terms of Service</a></div>
              <div className='mx-2 text-sm'><a rel='noopener noreferrer' target='_blank' href='https://www.thesuperbill.com/privacy' className="hover-underline-animation cursor-pointer">Privacy Notice</a></div>
              <div className='mx-2 text-sm'><a rel='noopener noreferrer' target='_blank' href='https://www.thesuperbill.com/release-notes' className="hover-underline-animation cursor-pointer">Change Log</a></div>
            </div> */}
          </div>
          </div>
        </>
      ) : null}
      <InviteMembersModal isOpen={inviteUserOpenState} setIsOpen={setInviteUserOpenState} onCancel={() => {
        setInviteUserOpenState(false)
      }} onConfirm={async () => {
        setInviteUserOpenState(false)
      }} />
      <SuperDialTutorialModal modalIsOpen={tutorialModalOpenState} onClose={() => {
        setTutorialModalOpenState(false)
        updateRobodialerUser({
          viewedTutorial: Date.now(),
        });
      }} />
    </>
  )
}