import React, { useEffect, useState } from "react";

// custom styles
const customStyles = {
  content: {
    height: "max(70%,550px)",
    maxWidth: "max(70%,550px)",
    margin: "auto",
    padding: 0,
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

interface Props {
  goToCreatePage: () => void;
  onClose: () => void;
}

// components
export default function InboundScriptInstructionsPage({
  goToCreatePage,
  onClose,
}: Props) {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        You unlocked a new feature! 🎉
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        <div className="text-base text-superbill-jacarta">
          Let us guide you through the new inbound call feature. We'll show you
          what each field represents and how to configure it.
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4">
          <div className="grid-cols-1 border rounded border-superbill-soap p-4 h-[200px]">
            {/* Introduction Message */}
            <div className="">
              <label
                htmlFor="introduction"
                className="block text-xs font-medium text-superpay-light-gray"
              >
                Introduction Message:
              </label>
              <textarea
                placeholder="E.g., Hi, this is Jim, how can I assist you today?"
                className="w-full rounded outline-none text-base border border-superbill-anti-flash-white-darken disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[120px]"
                disabled={true}
              />
            </div>
          </div>
          <div className="grid-cols-1 border rounded border-superbill-soap p-4 h-[200px] ">
            {/* Bot Instructions */}
            <div className="">
              <label
                htmlFor="instructions"
                className="block text-xs font-medium text-superpay-light-gray"
              >
                Bot Instructions:
              </label>
              <textarea
                placeholder="E.g., You are a dental receptionist. Ask for name, DOB, and reason for the call."
                className="w-full rounded outline-none text-base border border-superbill-anti-flash-white-darken disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[120px]"
                disabled={true}
              />
            </div>
          </div>
          <div className="grid-cols-1 border rounded border-superbill-soap p-4 h-[200px] ">
            {/* Transfer Number */}
            <div className="">
              <label
                htmlFor="transferNumber"
                className="block text-xs font-medium text-superpay-light-gray"
              >
                Transfer Phone Number:
              </label>
              <input
                type="tel"
                placeholder="E.g., 1234567890"
                className="w-full rounded outline-none text-base border border-superbill-anti-flash-white-darken disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
                disabled={true}
              />
            </div>
            {/* Transfer Conditions */}
            <div className="mt-2">
              <label
                htmlFor="transferConditions"
                className="block text-xs font-medium text-superpay-light-gray"
              >
                Transfer Conditions:
              </label>
              <textarea
                placeholder="E.g., Transfer the call if the bot cannot handle the query."
                className="w-full rounded outline-none text-base border border-superbill-anti-flash-white-darken disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-2">
          <div className="grid-cols-1">
            <div className="text-sm text-superbill-jacarta font-bold">
              Greeting your callers
            </div>
            <div className="text-xs text-superbill-jacarta">
              This is the greeting your callers will hear when they reach your
              bot. Keep it friendly and professional! For example: 'Hi, this is
              Jim, how can I help you?'
            </div>
          </div>
          <div className="grid-cols-1">
            <div className="text-sm text-superbill-jacarta font-bold">
              Provide instructions for the bot to handle calls
            </div>
            <div className="text-xs text-superbill-jacarta">
              Include key details like your role, location, and what information
              the bot should collect.
            </div>
          </div>
          <div className="grid-cols-1">
            <div className="text-sm text-superbill-jacarta font-bold">
              Transfer Conditions
            </div>
            <div className="text-xs text-superbill-jacarta">
              Optionally, define when calls should be transferred to a live
              representative. Specify the phone number and conditions for the
              transfer.
            </div>
          </div>
        </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
          onClick={onClose}
        >
          Close
        </button>

        <button
          onClick={goToCreatePage}
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
        >
          Got it!
        </button>
      </div>
    </div>
  );
}
