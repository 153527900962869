import { DateTime } from 'luxon';
import React, { Fragment, useState} from 'react'
import { Tooltip } from '@material-tailwind/react';
//import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import { ReactComponent as SparklesIcon } from 'assets/sparkles2.svg';
import { ReactComponent as OpenIcon } from 'assets/open.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as RunIcon } from 'assets/run.svg';
import { ReactComponent as RenameIcon } from 'assets/rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ViewInputsIcon } from 'assets/view-inputs.svg';
import { ReactComponent as DownloadInputsIcon } from 'assets/download.svg';
import { ReactComponent as DefineInputsIcon } from 'assets/define-inputs.svg';



type NewScriptMenuProps = {
  onCreateOutbound: () => void;
  onCreateInbound: () => void;
};


const NewScriptMenu = ({
  onCreateOutbound,
  onCreateInbound
  
}: NewScriptMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='flex'>
        <Button
          className='w-full !p-0 !normal-case'
          id={`basic-scripts-button-${new Date().getTime()}`}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <span className="sr-only">Scripts</span>
          <div
            className='bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300'
          >
            New Script
          </div>
        </Button>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          <div className="py-1 border border-superbill-soap border-1 rounded">
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { 
                  onCreateOutbound();
                  handleClose();
                 }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Outbound Script</div>
              </div>
            </MenuItem>
            <div className='border-b border-superbill-soap'></div>            
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { 
                  onCreateInbound();
                  handleClose();
                 }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Inbound Script</div>
              </div>
            </MenuItem>
          </div>
      </Menu>
    </>
  );
};

export default NewScriptMenu;
