import { DateTime } from 'luxon';
import React, { Fragment, useState} from 'react'
import { Tooltip } from '@material-tailwind/react';
//import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import toast from 'react-hot-toast';
import { ReactComponent as SparklesIcon } from 'assets/sparkles2.svg';
import { ReactComponent as OpenIcon } from 'assets/open.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as RunIcon } from 'assets/run.svg';
import { ReactComponent as RenameIcon } from 'assets/rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as ViewInputsIcon } from 'assets/view-inputs.svg';
import { ReactComponent as DownloadInputsIcon } from 'assets/download.svg';
import { ReactComponent as DefineInputsIcon } from 'assets/define-inputs.svg';



type InboundScriptCardProps = {
  onEdit: (id: string) => void;
  scriptData: any;
  scriptIndex: number;
};

const onCopy = (id) => {
  navigator.clipboard.writeText(id);
  toast.success('Copied to clipboard', {
    iconTheme: {
      primary: '#4a43cd',
      secondary: '#ffffff',
    },
  }
  );
}

const InboundScriptCard = ({
  scriptData,
  scriptIndex,
  onEdit,
}: InboundScriptCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <div className='flex'>
        <Button
          className='w-full !p-0 !normal-case'
          id={`basic-button-${new Date().getTime()}`}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <span className="sr-only">Open options</span>
          <div
            key={scriptData.id}
            className="bg-white mt-4 rounded !border border-superbill-soap px-5 py-3 justify-center items-center hover:border-superbill-ultramarine w-full min-w-[325px] lg:w-[325px] grow ease-in-out duration-150"
          >
            <div className='flex justify-between items-center'>
              {scriptData?.name?.length >= 32 ?
                <Tooltip className='bg-black py-1 px-2'>
                  <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{scriptData?.name}</div>
                </Tooltip>
                :
                <div className='text-left mr-2 text-superbill-jacarta text-base font-jakarta font-semibold truncate'>{scriptData?.name}</div>}
              
            </div>
            <div className='text-sm text-start text-superpay-light-text-gray'>ID: {scriptData?.id}</div>
            <div className='text-sm text-start text-superpay-light-text-gray'>Created: <span className='text-superpay-light-text-gray text-sm font-sans'>{
                DateTime.fromJSDate(new Date(scriptData?.createdAt)).toFormat(
                  'MMM d'
                )}</span></div>
            {scriptData?.phoneNumber ?
              <div className='text-sm text-start text-superpay-light-text-gray'>Phone Number: {scriptData?.phoneNumber ?? ''}</div>
            : null}
            <div className='flex justify-end'><span className={`${scriptData?.status === 'ready' ? 'text-green-600' : 'text-orange-500 italic'} text-xs capitalize`}>{scriptData?.status ?? ''}</span></div>
          </div>
        </Button>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          <div className="py-1 border border-superbill-soap border-1 rounded">
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { 
                  onEdit(scriptData?.id);
                  handleClose();
                 }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>View/Edit Script</div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { 
                  onCopy(scriptData?.phoneNumber);
                  handleClose();
                 }}
              >
                <OpenIcon className="w-[20px]" />
                <div className='mx-2 text-base'>Copy Phone Number</div>
              </div>
            </MenuItem>
            
            <div className='border-b border-superbill-soap'></div>
            {/* <MenuItem>
              <div className='hover:bg-superbill-ghost-white hover:text-superbill-indigo text-superbill-gray flex cursor-pointer w-full px-4 py-2 text-left items-center ease-in-out duration-150'
                onClick={() => { onDelete(id);
                  handleClose();
                 }}
              >
                <DeleteIcon className="w-[20px]"/>
                <div className='mx-2 text-base'>Delete Script</div>
              </div>
            </MenuItem> */}
          </div>
      </Menu>
    </>
  );
};

export default InboundScriptCard;
