import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Loader from "components/Loader";
import {
  getBatches,
  getCallsOverview,
  getRobodialerInboundCalls,
} from "apis/robodialer";

import { RobodialerUser } from "types/types";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import ResultsTable from "../scripts-results-table";
import ScriptsDetailedResultsTable from "script-builder/scripts-detailed-results-table";
import CallsOVerviewOptions from "../calls-overview-options";
import { Tooltip } from "@material-tailwind/react";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as TotalIcon } from "assets/icon_phone.svg";
import { ReactComponent as SuccessIcon } from "assets/icon_check-circle.svg";
import { ReactComponent as FailIcon } from "assets/icon_xmark-circle.svg";
import InboundWIPPage from "./WIP-page";
import InboundCallsResultsTable from "./inbound-results-table";
import CallDetails from "./call-detailed-result";

type PathType = {
  name: string;
  href: string;
  current: boolean;
};

type PageProps = {
  userData: RobodialerUser | null;
};
export default function InboundResultsPage() {
  const [props] = useOutletContext<Array<PageProps>>();
  const { userData } = props;
  console.log("userData", userData);

  const [loading, setLoading] = useState<boolean>(true);

  const [inboundCalls, setInboundCalls] = useState<any>(null);
  const [overviewData, setOverviewData] = useState<any>(null);
  const [selectedCall, setSelectedCall] = useState<string | null>(null);

  const fetchCalls = async () => {
    setLoading(true);
    const response = await getRobodialerInboundCalls();
    console.log("getRobodialerInboundCalls response", response);

    setInboundCalls(response.data);
    setLoading(false);
  };
  useEffect(() => {
    fetchCalls();
  }, []);

  return (
    <>
      <Toaster toastOptions={{ duration: 12000 }} />
      {userData?.featureFlags && userData?.featureFlags?.allowInboundCalls ? (
        <>
          {loading ? (
            <>
              <div className="w-full h-full flex justify-center items-center">
                {" "}
                <Loader />{" "}
              </div>
            </>
          ) : null}
          {!loading && inboundCalls ? (
            <>
              {selectedCall ? (
                <>
                  <CallDetails setSelectedCall={setSelectedCall} callData={inboundCalls.data.find(
                      (call) => call.callId === selectedCall
                    )}/>
                </>
              ) : (
                <InboundCallsResultsTable
                  setSelectedCall={setSelectedCall}
                  callsData={inboundCalls.data}
                  overviewData={inboundCalls.metadata}
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        <InboundWIPPage />
      )}
    </>
  );
}
