import React, { useState } from 'react'
import { DateTime } from 'luxon';
import { ReactComponent as ForwardIcon } from "assets/pagination-forward-arrow.svg";
import { ReactComponent as BackIcon } from "assets/pagination-backward-arrow.svg";
import { ReactComponent as NoResults } from "assets/no-script-results.svg";
import { PaginationStates, paginationHelper } from 'utils/pagination';
import { toast } from 'react-hot-toast';

import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import CallsOVerviewOptions from '../calls-overview-options';
import { ReactComponent as InfoIcon } from "assets/info.svg";
import { ReactComponent as TotalIcon } from 'assets/icon_phone.svg';
import { ReactComponent as SuccessIcon } from 'assets/icon_check-circle.svg';




type InboundCallsResultsTableProps = {
  callsData: any;
  overviewData: any;
  setSelectedCall: (id: string) => void;
}


function InboundCallsResultsTable({ callsData, overviewData, setSelectedCall }: InboundCallsResultsTableProps) {
  const [selectedOption, setSelectedOption] = useState<string>('All time');
  const [currentResultsPageIndex, setCurrentResultsPageIndex] =
    useState<number>(0);

  const {
    newEntries: currentActiveResults,
    currentLowerIndex,
    currentUpperIndex,
    changePagination,
  } = paginationHelper({
    entries: callsData,
    currentPageIndex: currentResultsPageIndex,
    maxEntriesPerPage: 30,
    setCurrentPageIndex: setCurrentResultsPageIndex,
  });
  
  const onCopy = (id) => {
    navigator.clipboard.writeText(id);
    toast.success('Copied to clipboard', {
      iconTheme: {
        primary: '#4a43cd',
        secondary: '#ffffff',
      },
    }
    );
  }



  return (
    <>
      {callsData.length === 0 ? <>
        <NoResults className="w-full text-center mb-4" />
        <div className="font-sans w-full text-center items-center text-superpay-light-text-gray">
          No inbound calls results available
        </div>
      </> :
        <>
          <div className='flex justify-between items-center'>
            <div className='text-superbill-jacarta font-bold text-base font-jakarta'>Summary</div>
            {/* <div className='text-superbill-slate-grey text-sm font-sans'><CallsOVerviewOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} /></div> */}
            <div className='text-superbill-slate-grey text-sm font-sans'>All time</div>
          </div>
          <div className='flex my-4'>
              <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 mr-2'>
                  <div className='flex items-center'>
                      <TotalIcon className='w-[12px]'/>
                      <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Inbound Calls</div>
                  </div>
                  <div className='text-xl text-superbill-jacarta'>{overviewData?.total ?? 0}</div>
              </div>
              <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3'>
                  <div className='flex items-center'>
                      <SuccessIcon className='w-[12px]'/>
                      <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Average Duration</div>
                  </div>
                  <div className='text-xl text-superbill-jacarta'>{(overviewData?.avgDuration/60 ?? 0).toFixed(2)} Min</div>
              </div>
              <div className='border border-superbill-soap rounded bg-white/30 p-3 basis-1/3 ml-2'>
                  <div className='flex items-center'>
                      <InfoIcon className='w-[12px]'/>
                      <div className='font-semibold text-superbill-slate-grey text-sm text-jakarta ml-2'>Time Saved</div>
                  </div>
                  <div className='text-xl text-superbill-jacarta'>{((overviewData?.totalDuration/60) ?? 0).toFixed(2)} Min</div>
              </div>
          </div>

          <div className="grid grid-cols-10 md:grid-cols-14 w-full mt-4 font-semibold font-sans text-superbill-jacarta rounded-t border-t border-l border-r border-superbill-soap overflow-hidden">
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1'>Date</div>
            <div className='col-span-3 text-sm bg-superbill-anti-flash-white px-2 py-1'>Call ID</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1'>Phone Number</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1'>Script</div>
            <div className='col-span-1 text-sm bg-superbill-anti-flash-white px-2 py-1'>Duration</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1 hidden md:block'>Start</div>
            <div className='col-span-2 text-sm bg-superbill-anti-flash-white px-2 py-1 hidden md:block'>End</div>
          </div>
          <div className='w-full bg-white rounded-b border border-superbill-soap  divide-y divide-superbill-soap font-sans'>
            {currentActiveResults?.map((entry: any) => {
              return (
                <div className='grid grid-cols-10 md:grid-cols-14 font-sans items-center hover:bg-superbill-lavender/50  ease-in-out duration-150' key={'inbound-table-'+entry.callId}>
                  <div className='col-span-2 text-superbill-slate-grey text-base font-light px-2 py-1 flex items-center gap-x-1'>{DateTime.fromJSDate(new Date((entry.added_at))).toFormat('MM/dd/yyyy')} </div>
                  <div className='col-span-3 text-superbill-slate-grey text-base px-2 py-1 relative truncate group cursor-pointer flex items-center'>
                    <span className={`truncate cursor-pointer text-superbill-indigo hover-underline-animation `} 
                     onClick={entry.status !== "processing" ? () => setSelectedCall(entry.callId) : undefined}>{entry.callId}</span>
                    <div className='absolute right-0 bottom-2 group-hover:block cursor-pointer bg-[#f3f2fe] px-2' onClick={() => { onCopy(entry.callId ?? '') }}>
                      <CopyIcon className='hover:fill-superbill-indigo'/>
                    </div>
                  </div>
                  
                  <div className='col-span-2 items-center text-superbill-jacarta text-base px-2 py-1 truncate'>{entry.phoneNumber?.number}</div>
                  <div className='col-span-2 truncate text-superbill-jacarta text-base px-2 py-1'>{entry.scriptName ?? entry.scriptId}</div>
                  <div className='col-span-1 text-superbill-jacarta text-base px-2 py-1'>
                    <span className='text-superbill-slate-grey italic'>{entry.duration}S</span>
                  </div>
                  
                  <div className={`w-full items-center justify-between col-span-2 px-2 py-1 hidden md:flex`}>
                    <span className={`${entry.status !== "completed" ? 'text-superbill-jacarta' : 'italic text-superbill-slate-grey'} text-base`}>
                      {entry?.started_at ?
                        <>{DateTime.fromJSDate(new Date(entry?.started_at)).toFormat('MM/dd/yyyy HH:mm')}
                        </>
                        :
                        <div className='flex items-center'>-</div>
                      }
                    </span>
                  </div>
                  <div className={`w-full items-center justify-between col-span-2 px-2 py-1 hidden md:flex`}>
                    <span className={`${entry.status !== "completed" ? 'text-superbill-jacarta' : 'italic text-superbill-slate-grey'} text-base`}>
                      {entry?.ended_at ?
                        <>{DateTime.fromJSDate(new Date(entry?.ended_at)).toFormat('MM/dd/yyyy HH:mm')}
                        </>
                        :
                        <div className='flex items-center'>-</div>
                      }
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-end flex-row px-2 pt-2">
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.backward)}
            >
              {currentLowerIndex == 0 ? <></> : <BackIcon />}
            </div>
            <div className="mx-2">
              <strong>{currentLowerIndex + 1} to {currentUpperIndex + 1}</strong> of{" "}
              {callsData?.length}
            </div>
            <div
              className="cursor-pointer mt-1 fill-superbill-gray-2 hover:fill-superbill-ultramarine"
              onClick={() => changePagination(PaginationStates.forward)}
            >
              {currentUpperIndex + 1 == callsData?.length ? <></> : <ForwardIcon />}
            </div>
          </div>
        </>
      }
    </>
  )
}

export default InboundCallsResultsTable