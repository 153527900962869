import React, { useEffect, useState } from "react";
import { ReactComponent as Spinner } from 'assets/button-loading.svg';
import InboundScriptForm from "../components/new-script-form";
import { useForm } from "react-hook-form";
import { createInboundScript, updateInboundScript } from "apis/robodialer";
import { toast } from "react-hot-toast";

interface InboundScriptFormData {
  name: string;
  introduction: string;
  instructions: string;
  transferNumber?: string;
  transferConditions?: string;
}
export default function NewInboundScriptPage({ onClose, onSubmit, selectedScriptId, scriptData }) {

  const [isLoading, setLoading] = useState<boolean>(false);
  
  const {
    register,
    watch
  } = useForm<InboundScriptFormData>({
    defaultValues: {
      name: scriptData && scriptData.name ? scriptData.name : '',
      introduction: scriptData && scriptData.introduction ? scriptData.introduction : '',
      instructions: scriptData && scriptData.instructions ? scriptData.instructions : '',
      transferNumber: scriptData && scriptData.transferNumber ? scriptData.transferNumber : '',
      transferConditions: scriptData && scriptData.transferConditions ? scriptData.transferConditions : '',
    }
  });

  const handleSubmit = async () => {
    console.log(watch());
    let data = {
      "introduction": watch('introduction'),
      "instructions": watch('instructions'),
      "transferNumber": watch('transferNumber'),
      "transferConditions": watch('transferConditions'),
      "name": watch('name'),
    }
    if(!data.instructions || !data.introduction || !data.name) {
      toast.error("Please fill out all required fields", {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
          },
      });
      return
    }
    setLoading(true);
    let response = selectedScriptId ? await updateInboundScript(selectedScriptId, data) : await createInboundScript(data)
    setLoading(false);
    if(response.httpCode !== 200){
      let msg = response.data?.error ?? 'Unable to create script'
      toast.error(response.httpCode + ' ' + msg, {
        iconTheme: {
          primary: '#F36F82',
          secondary: '#ffffff',
          },
      });
      return
    }else{
      toast.success(`Script ${selectedScriptId ? 'updated' : 'created'} successfully`, {
        iconTheme: {
          primary: '#4a43cd',
          secondary: '#ffffff',
          },
      });
    }
    onSubmit()
  }
  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        Inbound Script Configuration
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
          <InboundScriptForm register={register}/>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-between px-4 py-3">
        <button
          className="bg-transparent font-jakarta hover:bg-superbill-lavender-dark/50 text-superbill-jacarta font-semibold py-2 px-5 text-sm border border-superbill-soap rounded-full ease-in-out duration-300"
          onClick={onClose}
        >
          Close
        </button>

        <button
          onClick={handleSubmit}
          disabled={
            isLoading
          }
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
        >
          {isLoading ? <Spinner /> : <>
            {!selectedScriptId ? 'Create' : 'Update'}
          </>}
        </button>
      </div>
    </div>
  );
}
