import React from "react";
import { ReactComponent as SupportIcon } from "assets/calendar.svg";

interface ConfiguringAgentProps {
  onConfigurationComplete: () => void;
}

const ConfiguringAgentPage: React.FC<ConfiguringAgentProps> = ({
  onConfigurationComplete,
}) => {
  return (
    <div className="flex flex-col h-full divide-y font-sans">
      <nav className="font-jakarta font-bold px-6 pt-7 pb-4 text-xl text-superbill-jacarta">
        We're excited to set up your new virtual assistant
      </nav>
      <div className="flex-grow overflow-y-auto p-9">
        <div className="w-full flex justify-center">
        <SupportIcon className="w-20 h-20 mt-4" />
        </div>
        <div className="text-center py-8">
          <p className="text-superbill-jacarta text-base font-bold">
            Your bot is now activating! We're connecting all the components to
            make it work flawlessly.
          </p>
          <p className="text-superbill-jacarta text-sm mt-2">
            Just a sec while we brew up the perfect bot for you... 🚀
          </p>
        </div>
      </div>
      {/* Toolbar */}
      <div className="flex flex-row justify-end px-4 py-3">
        <button
          onClick={onConfigurationComplete}
          className="bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ConfiguringAgentPage;
