import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import Loader from "components/Loader";

import { ReactComponent as Spinner } from "assets/button-loading.svg";
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { getRobodialers } from '../../../apis/agent';
import { getRobodialersResponseType } from '../../../types/types';
import { RobodialerSelect } from './robodialer_select';

export type FilterFormValues = {
  filterBy?: string;
  dateOption?: string;
  dateFrom?: string;
  dateTo?: string;
  robodialer?: {label: string, value:string};
  batchStatus?: string;
  robodialerId?: string;
  cancelled?: string;
  paused?: string;
  hidden?: string;
  batchId?: string;
  scriptId?: string;
  callId?: string;
};

interface Option {
  value: boolean | '';
  label: string;
}

type FilterFormProps = {
  showBatchOption?: boolean;
  search: (data:FilterFormValues) => void;
  defaultValues?: any;
  showSearchButton?: boolean;
}
const FilterForm = forwardRef(({search, defaultValues, showBatchOption=true, showSearchButton=true}:FilterFormProps, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [robodialersData, setRobodialersData] = useState<null | getRobodialersResponseType>(null);
  
  const [defaultRobodialer, setDefaultRobodialer] = useState<{ label: string; value: string } | null>(null);
  
  
  
  const { control, handleSubmit, setValue, watch, reset } = useForm<FilterFormValues>({
    defaultValues: {
      filterBy: defaultValues?.filterBy ?? '',
      dateOption: defaultValues?.dateOption ?? '',
      batchId: defaultValues?.batchId ?? '',
      scriptId: defaultValues?.scriptId ?? '',
      callId: defaultValues?.callId ?? '',
      cancelled: defaultValues?.cancelled ?? '',
      paused: defaultValues?.paused ?? '',
      hidden: defaultValues?.hidden ?? '',
      batchStatus: defaultValues?.batchStatus ?? '',
      dateFrom: defaultValues?.dateFrom ?? '',
      dateTo: defaultValues?.dateTo ?? '',
      },
    });
      
    const dateOption = watch('dateOption');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    useEffect(() => {
      const selectedRobo = robodialersData?.robodialers?.find(e => e.id === defaultValues?.robodialerId)
  
      console.log('selectedRobo', selectedRobo);
      if (selectedRobo) {
        setValue('robodialer', { label: selectedRobo.name, value: selectedRobo.id });
      }
    }, [robodialersData]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      const { data } = await getRobodialers();
      setIsLoading(false)
      setRobodialersData(data)
    }
    fetch()
  
  }, []);

  /* const onSubmit: SubmitHandler<FilterFormValues> = (data) => {
    console.log(data);
  }; */

  

  const onSubmit = () => {
    console.log(watch());
    
    search(watch() as FilterFormValues)
  }
  useImperativeHandle(ref, () => ({ onSubmit }));
  
  return (
    <>
      {isLoading ? (
        <>
          <div className="w-full h-full flex justify-center items-center">
            {" "}
            <Loader />{" "}
          </div>
        </>
      ) : null}
      {!isLoading ? (
      <form>
        <div className="grid grid-cols-4 gap-x-4">
        {showBatchOption ?
          <div className='col-span-1'>
              <label className='text-sm text-superbill-jacarta'>Filter By:</label>
              <Controller
                name="filterBy"
                control={control}
                render={({ field }) => (
                  <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                    <option value="">Select...</option>
                    <option value="createdAt">Creation Date</option>
                    <option value="dueDate">Due Date</option>
                  </select>
                )}
              />
            </div>
        :null}

          <div className='col-span-1'>
            <label className='text-sm text-superbill-jacarta'>Options:</label>
            <Controller
              name="dateOption"
              disabled={!watch('filterBy')}
              control={control}
              render={({ field }) => (
                <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                  <option value="">Select...</option>
                  <option value="lastMonth">Last month</option>
                  <option value="lastWeek">Last week</option>
                  <option value="lastTwoWeeks">Last 2 weeks</option>
                  <option value="sinceBeginning">Since the beginning</option>
                  <option value="today">Today</option>
                  <option value="custom">Custom</option>
                </select>
              )}
            />
          </div>

          {dateOption === 'custom' && (
            <>
              <div className='col-span-1'>
                <label className='text-sm text-superbill-jacarta'>Date From:</label>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="date" {...field} />
                  )}
                />
              </div>
              <div className='col-span-1'>
                <label className='text-sm text-superbill-jacarta'>Date To:</label>
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="date" {...field} />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-4 gap-x-4 mt-4">
          {showBatchOption ? 
            <>
              <div className='col-span-1'>
                <label className='text-sm text-superbill-jacarta'>Batch Status:</label>
                <Controller
                  name="batchStatus"
                  control={control}
                  render={({ field }) => (
                    <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                      <option value="">Select...</option>
                      <option value="processing">Processing</option>
                      <option value="completed">Completed</option>
                    </select>
                  )}
                />
              </div>
              <div className='col-span-1'>
              <label className='text-sm text-superbill-jacarta'>Cancelled:</label>
              <Controller
                name="cancelled"
                control={control}
                render={({ field }) => (
                  <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                    <option value="">Select...</option>
                    <option value={"1"}>Yes</option>
                    <option value={"0"}>No</option>
                  </select>
                )}
              />
              </div>
              <div className='col-span-1'>
                <label className='text-sm text-superbill-jacarta'>Paused:</label>
                <Controller
                  name="paused"
                  control={control}
                  render={({ field }) => (
                    <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                      <option value="">Select...</option>
                      <option value={"1"}>Yes</option>
                      <option value={"0"}>No</option>
                    </select>
                  )}
                />
              </div>
              <div className='col-span-1'>
                <label className='text-sm text-superbill-jacarta'>Hidden:</label>
                <Controller
                  name="hidden"
                  control={control}
                  render={({ field }) => (
                    <select className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" {...field}>
                      <option value="">Select...</option>
                      <option value={"1"}>Yes</option>
                      <option value={"0"}>No</option>
                    </select>
                  )}
                />
              </div>
            </>
          : null}
          
          <div className='col-span-1'>
            {/* <label className='text-sm text-superbill-jacarta'>Robodialer ID:</label>
            <Controller
              name="robodialerId"
              control={control}
              render={({ field }) => (
                <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="text" {...field} />
              )}
            /> */}
            {RobodialerSelect('robodialer', control, robodialersData?.robodialers)}
          </div>
          
          {showBatchOption ? 
            <div>
            <label className='text-sm text-superbill-jacarta'>Batch ID:</label>
            <Controller
              name="batchId"
              control={control}
              render={({ field }) => (
                <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="text" {...field} />
              )}
            />
          </div>
          : null}
          {!showBatchOption ? 
            <div>
            <label className='text-sm text-superbill-jacarta'>Script ID:</label>
            <Controller
              name="scriptId"
              control={control}
              render={({ field }) => (
                <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="text" {...field} />
              )}
            />
          </div>
          : null}

          {/* <div>
            <label className='text-sm text-superbill-jacarta'>Call ID:</label>
            <Controller
              name="callId"
              control={control}
              render={({ field }) => (
                <input className="mt-1 block w-full p-2 border border-superpay-soap rounded-md" type="text" {...field} />
              )}
            />
          </div> */}
        </div>

        <div className="flex items-center gap-x-4 my-5">
          <div className="flex items-center justify-center">
            {showSearchButton ? 
              <button
                type="button"
                className="mb-1 bg-superbill-ultramarine disabled:bg-superbill-wild-blue-yonder font-jakarta hover:bg-superbill-indigo text-white text-sm font-semibold py-2 px-5 rounded-full ease-in-out duration-300"
                onClick={handleSubmit(search)}
                /* onClick={() => {
                  search(watch() as FilterFormValues)
                }} */
                disabled={isSearching}
              >
                {isSearching ? <Spinner /> : <>Search</>}
              </button>
            : null}
          </div>
          <button className="w-fit text-xs cursor-pointer hover-underline-animation" type="button" onClick={() => reset()}>Clear Filters</button>
        </div>
      </form>
      ):null}
    </>
  );
});

export default FilterForm;
