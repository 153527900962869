import { getAuth } from 'firebase/auth';

// Body is non-null when request has payload
// Form is non-null when request has payload including one or more file objects
// Body and form should never both be non-null
export async function makeRequest(path, operation, auth, body, form, returnData = false, apiToUse = 'portal') {
  const APIs = {
    portal: {
      url: process.env.REACT_APP_API_URL,
      token: await getAuth()?.currentUser?.getIdToken()
    },
    sbs: {
      url: process.env.REACT_APP_SBS_API_URL,
      token: getAuth()?.currentUser?.getIdToken()
    },
   }

  // DO NOT TOUCH THIS
  const baseUrl = APIs[apiToUse].url;
  // let baseUrl = "https://portal-api-9nc4t1p9.uc.gateway.dev";
  // //let baseUrl = "http://127.0.0.1:8080";


  const url = `${baseUrl}${path}`;

  const payload = {
    method: operation,
  };

  payload['headers'] = {};

  /*
  if (auth && process.env.REACT_APP_ENV !== 'Development') {
    const tokenResult = (await getAuth()?.currentUser?.getIdTokenResult()) || {
      token: '',
    };
    payload.headers['Authorization'] = 'Bearer ' + tokenResult.token;
  } else {
    console.log('no auth token set');
  }
  */
  //apiToUse = forceAgentToken ? 'sbs' : apiToUse
  if (auth && baseUrl !== 'http://127.0.0.1:8080') {
    const tokenResult = await APIs[apiToUse].token
    
    payload.headers['Authorization'] = 'Bearer ' + tokenResult;
  } else {
    //console.log('no auth token set');
  }

  if ((operation === 'POST' || operation === 'PUT') && body) {
    payload.body = JSON.stringify(body);
    payload.headers['Accept'] = 'application/json';
    payload.headers['Content-Type'] = 'application/json';
  }

  if ((operation === 'POST' || operation === 'PUT') && form) {
    payload.body = form;
  }

  let msg = '';
  let fullResponse = {
    status: 999,
    data: {
      error: 'No response from server. Please check your internet connection and try again',
    },
    error: 'No response from server. Please check your internet connection and try again',
  };
  let responseJson = {};
  try {
    fullResponse = await fetch(url, payload);
    if(returnData){
      return {
        msg : "",
        httpCode: fullResponse.status,
        data: fullResponse,
      }
    }
    responseJson = await fullResponse.json();
    if (process.env.REACT_APP_ENV === 'Development') {
      //console.log(responseJson);
    }
  } catch (e) {
    msg = 'Error connecting to the server, check your internet connection'//e;
    if (process.env.REACT_APP_ENV === 'Development') {
      console.log(e);
    }
  }
  if (fullResponse.status === 500) {
    msg = 'Server error, contact support'
  }else if (fullResponse.status === 504) {
    msg = 'Timeout, try again'
  }
  return {
    msg,
    httpCode: fullResponse.status,
    data: responseJson,
  };
}
