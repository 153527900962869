import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";

export default function InboundScriptForm({register}) {

  return (
    <form className="space-y-4">
      <div>
        <label htmlFor="introduction" className="block text-xs font-medium text-superbill-jacarta">
          Name <span className="text-red-500">*</span>
        </label>
        <textarea
          id="name"
          {...register("name", { required: "This field is required" })}
          placeholder="Unique name for the script.  E.g., Dental Receptionist"
          className="text-sm w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-superbill-anti-flash-white focus:border-superbill-indigo focus:outline-none border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
        />
        
      </div>
      {/* Introduction Message */}
      <div>
        <label htmlFor="introduction" className="block text-xs font-medium text-superbill-jacarta">
          Introduction Message <span className="text-red-500">*</span>
        </label>
        <textarea
          id="introduction"
          {...register("introduction", { required: "This field is required" })}
          placeholder="E.g., Hi, this is Jim, how can I assist you today?"
          className="text-sm w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-superbill-anti-flash-white focus:border-superbill-indigo focus:outline-none border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
        />
        
      </div>

      {/* Bot Instructions */}
      <div>
        <label htmlFor="instructions" className="block text-xs font-medium text-superbill-jacarta">
          Bot Instructions <span className="text-red-500">*</span>
        </label>
        <textarea
          id="instructions"
          {...register("instructions", { required: "This field is required" })}
          placeholder="E.g., You are a dental receptionist. Ask for name, DOB, and reason for the call."
          className="text-sm w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-superbill-anti-flash-white focus:border-superbill-indigo focus:outline-none border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
        />
      
      </div>

      {/* Transfer Number */}
      <div>
        <label htmlFor="transferNumber" className="block text-xs font-medium text-superbill-jacarta">
          Transfer Phone Number (Optional)
        </label>
        <input
          type="tel"
          id="transferNumber"
          {...register("transferNumber", {
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          })}
          placeholder="E.g., 1234567890"
          className="text-sm w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-superbill-anti-flash-white focus:border-superbill-indigo focus:outline-none border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
        />
      </div>

      {/* Transfer Conditions */}
      <div>
        <label htmlFor="transferConditions" className="block text-xs font-medium text-superbill-jacarta">
          Transfer Conditions (Optional)
        </label>
        <textarea
          id="transferConditions"
          {...register("transferConditions")}
          placeholder="E.g., Transfer the call if the bot cannot handle the query."
          className="text-sm w-full rounded focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-superbill-anti-flash-white focus:border-superbill-indigo focus:outline-none border focus:bg-superbill-light-gray-background hover:bg-superbill-anti-flash-white disabled:bg-superbill-anti-flash-white p-1 font-sans min-h-[38px]"
        />
      </div>
    </form>
  );
}